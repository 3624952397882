import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faUserTie } from "@fortawesome/free-solid-svg-icons";

import "./style/history.css";

const History = (props) => {
	const { date, title, location, role, description } = props;

	return (
		<React.Fragment>
			<div className="history">
				<div className="history-left-side">
					<div className="history-date">{date}</div>
				</div>

				<div className="history-right-side">
					<div className="history-title">{title}</div>
					<div className="history-location">
						<FontAwesomeIcon
							style={{
								fontSize: "15px",
								marginRight: "10px",
								color: "green",
							}}
							icon={faLocationDot}
						/>
						{location} &nbsp;&nbsp;&nbsp;|
						<FontAwesomeIcon
							style={{
								fontSize: "15px",
								marginLeft: "10px",
								marginRight: "10px",
								color: "green",
							}}
							icon={faUserTie}
						/>
						{role}
					</div>
					{description.map((detail, index) => (
						<div className="history-description" key={index}>
							{detail}
						</div>
					))}
				</div>
			</div>
		</React.Fragment>
	);
};

export default History;
