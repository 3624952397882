const INFO = {
	main: {
		title: "Erik C. Olson",
		name: "Erik C. Olson",
		email: "eolson@erikcaineolson.com",
		logo: "../logo.png",
	},

	socials: {
		github: "https://github.com/erikcaineolson",
		linkedin: "https://www.linkedin.com/in/erikcaineolson/",
	},

	homepage: {
		title: "Full-Stack Web Developer and Internet Marketer",
		description: [
			"Accomplished developer with a proven ability to architect and implement web-based software solutions supporting business, government, and financial objectives. ",
			"I have a long history of web application development: leading teams, working in teams, and working alone. I’m equally at home in all of these situations. " +
			"I lean heavily on the LEMP stack (especially with Laravel) using React and Vue on the front end, but am comfortable in Python, Java, C#, and Angular.",
		],
	},

	about: {
		title: "Erik.C Olson",
		description: [
			"I've been involved in Internet Marketing and web programming since 1996, back in the days of Perl scripts, the Common Gateway Interface, and flat files.",
			"I'm a full-stack developer (stuff you see and interact with; stuff you don't see, like logic and databases; and even 'deeper' stuff you've probably never had to think about the software and hardware that turn a collection of files into a website or application). While I'm most familiar with PHP on NGINX servers, I have no issues working with Apache (NGINX is faster and lighter, but if you need something like cPanel, you don't have that option). I manage my own servers in the cloud (AWS and Digital Ocean) as well as co-located boxes. I maintain a passing familiarity with Python, Ruby on Rails, and PostgreSQL. I developed a 'lightweight' control panel in PHP using the Laravel framework for NGINX servers.",
			"I built a couple of mobile apps, but I haven't kept them up-to-date, so they're no longer available on the AppStore or GooglePlay. I still have an old iPod Touch somewhere with my first game on it...",
			"I've submitted a proposal to YCombinator, been the developer behind a Kickstarter, and am always looking for interesting projects I can benefit.",
		],
	},

	history: {
		title: "Work Experience",
		description:
			"Accomplished developer with a proven ability to architect and implement web-based software solutions supporting business, government, and financial objectives. ",
	},

	projects: [
		{
			title: "NuLIRT",
			description:
				"Nebraska University's Laboratory Information Reporting Technology provides enables Nebraska public health officials to improve speed, efficiency of identification, and treatment of disease.",
			logo: "/images/logos/unmc.png",
			linkText: "View Project",
			link: "https://nulirt.nebraskamed.com",
		},

		{
			title: "CalVax Statewide Vaccine Tracking",
			description:
				"The precursor to the California Department of Public Health's myCAvax, built as an emergency stop-gap in 2020 to track early COVID-19 vaccine inventory. Allowed transfers between health departments as needed.",
			logo: "/images/logos/mycavax.png",
			linkText: "View Project",
			link: "https://mycavax.cdph.ca.gov/s",
		},

		{
			description:
				"Inter-county accounting for county-provided mental healthcare for foster youth. Ensures counties get paid in a timely manner according to California state law.",
			logo: "/images/logos/calmhsa.png",
			linkText: "View Project",
			link: "https://calmhsa-members.org",
		},

		{
			title: "MedReport Guard",
			description:
				"An online hub for managing forensic medical reporting, mandatory reports, cross-agency referrals, survivor workflows and detailed data analysis. Enables survivor care collaboration.",
			logo: "/images/logos/medreportguard.png",
			linkText: "View Project",
			link: "https://medreportguard.com/",
		},

		{
			title: "CalFire Electronic Health Questionnaire",
			description:
				"CalFire's electronic questionnaires for OSHA compliance; used in conjunction with medical assessments for ensuring employee safety and reviewing health concerns.",
			logo: "/images/logos/calfire.png",
			linkText: "View Project",
			link: "https://calfire-emq.ucdavis.edu/",
		},

		{
			title: "Chancellor's Office Course Inventory",
			description:
				"Course and Program inventory for annual curriculum approval certification by the California Community College Chancellor's Office, ensuring colleges are appropriately compensated.",
			logo: "/images/logos/ccc.png",
			linkText: "View Project",
			link: "https://coci2.ccctechcenter.org/",
		},

		{
			title: "Course Identifier System",
			description:
				"C-ID is a supranumbering system to ease transfer and articulation across California's higher education institutions, with almost 500 descriptors covering nearly 60 different disciplines.",
			logo: "/images/logos/ccc.png",
			linkText: "View Project",
			link: "https://c-id.net/",
		},
	],
};

export default INFO;
