import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faUserTie } from "@fortawesome/free-solid-svg-icons";

import "./styles/history.css";

const History = (props) => {
	const { title, description, date, role, location } = props;

	return (
		<React.Fragment>
			<div className="homepage-history">
				<div className="homepage-history-content">
					<div className="homepage-history-date">
						|&nbsp;&nbsp;&nbsp;{date}
					</div>
					<div className="homepage-history-title">{title}</div>
					<div className="history-location">
						<FontAwesomeIcon
							style={{
								fontSize: "15px",
								marginRight: "10px",
								color: "green",
							}}
							icon={faLocationDot}
						/>
						{location} &nbsp;&nbsp;&nbsp;|
						<FontAwesomeIcon
							style={{
								fontSize: "15px",
								marginLeft: "10px",
								marginRight: "10px",
								color: "green",
							}}
							icon={faUserTie}
						/>
						{role}
					</div>
					{description.map((detail, index) => (
						<div
							className="homepage-history-description"
							key={index}
						>
							{detail}
						</div>
					))}
				</div>
			</div>
		</React.Fragment>
	);
};

export default History;
