function history_1() {
	return {
		date: "October 2021 – Present",
		title: "Univ. of NE Medical Center via Oxford Solutions",
		location: "Omaha, NE, US",
		role: "Sr. Software Engineer",
		description: [
			"Debug and create new features for public health application (i.e., medical printer integration)",
			"Upgrade legacy Laravel 6 application running on PHP 7.x to Laravel 9 on PHP 8.2",
			"Provide code reviews and general PHP support/knowledge transfer",
			"Integrate Zebra and Dymo medical label printers (ZPL and XML, respectively)",
			"Implemented UI, and 100% standards-based Web Components using React.js and Tailwind CSS."
		],
	};
}

function history_2() {
	return {
		date: "June 2021 – September 2021",
		title: "MedReportGuard",
		location: "Los Angeles, CA, US",
		role: "Full Stack Developer",
		description: [
			"Implement two-factor authentication (2FA) in Laravel projects",
			"Implement HIPAA security and user lockout process in primary Laravel project",
			"Created a user-friendly dashboard and campaign page using Vue and Quasar, while presenting more than 30 improved suggestions.",
		],
	};
}

function history_3() {
	return {
		date: "October 2020 – May 2021",
		title: "AgreeYa Solutions/CDPH",
		location: "Folsom, CA, US",
		role: "Lead Developer/Consultant",
		description: [
			"Lead team of 17 developers (10 backend, 3 frontend, 2 QA, 2 BA) for the CalVax COVID-19 Vaccination Allocation Platform",
			"Build solution for managing national-to-state-to-local (and inter-location) vaccine transfers and vaccine inventory (Laravel, Vue)",
			"Support legacy (PHP 5.x) air quality board management system covering violation reports, fixes, etc.",
		],
	};
}

function history_4() {
	return {
		date: "June 2019 – September 2020",
		title: "George Hills",
		location: "Rancho Cordova, CA, US",
		role: "Full Stack Developer/Consultant",
		description: [
			"Refactored the existing code to improve the performance of a heavy Angular front-end by talking to a Node.js and Express.js via REST API.",
			"Redesigned architecture of back-end system of Laravel framework to speed up loading car accessory data, achieved about 400% speed.",
			"Experienced in working with GraphQL queries and used Apollo GraphQL library",
			"Implemented pixel perfect design from PSD to build front-end with HTML5, CSS3, SASS, Bootstrap, React.js",
			"Utilized advanced ECMAScript 6 and 7 language features for more expressive, fexible code."
		],
	};
}

function history_5() {
	return {
		date: "August 2018 – June 2019",
		title: "PASCO Scientific",
		location: "Roseville, CA, US",
		role: "Lead Developer & System Architecture: Aug 2018 – June 2019",
		description: [
			"Optimized many large SQL reporting queries. The execution time went down from 8 seconds to one to two seconds. Sometimes it would be a sub-second. ",
			"Developed and maintained code for in-house and client websites primarily using HTML, CSS, SCSS, JavaScript, and jQuery. ",
			"Created a C++ add-on for Node.js for data passing to a parallel thread, processing, and returning to the JavaScript environment (Utilized the V8 JavaScript Engine API).",
			"Built fully automated CI/CD pipelines on CircleCI for ontainerized applications using Docker and DigitalOcean services."
		],
	};
}

function history_6() {
	return {
		date: "August 2016 – May 2018",
		title: "California Community College Tech Center",
		location: "Butte College Campus, Oroville, CA, US",
		role: "Lead Developer, C-ID and COCI",
		description: [
			"Drive direction (technical and business) of the college administrator platforms (course inventory and inter-college course articulation)",
			"Architect and lead full-stack development (Laravel); drive best practices; provide code reviews and mentorship",
			"Work with customer directly to improve processes and the application",
		],
	};
}

function history_7() {
	return {
		date: "March 2015 – Feb 2018",
		title: "UC Davis Health System",
		location: "UC Davis on- and off-campus, Davis, CA, US",
		role: "Application Programmer III",
		description: [
			"Architect and build electronic OSHA employee medical questionnaires for low-tech users (Symfony/AngularJS)",
			"Administer CentOS and Ubuntu servers hosting questionnaire and MariaDB database",
			"Implement Agile methodology in Public Health Sciences and Medical Surveillance departments",
		],
	};
}

function history_8() {
	return {
		date: "April 2014 – March 2015",
		title: "Kura MD",
		location: "Roseville, CA, US",
		role: "Sr. Software Engineer; Business Systems Analyst; Scrum Master; Incident Response",
		description: [
			"Ensure HIPAA compliance in legacy OO PHP and modern Laravel telemedicine platform",
			"Maintain and lead outsource team supporting legacy codebase and hardware integrations",
			"Implement Agile/Scrum tools and integrate with existing developer tools",
			"Reduce wasted developer time by 31% and improve developer/management communication",
		],
	};
}

function history_9() {
	return {
		date: "January 2008 – April 2014",
		title: "effina",
		location: "Greater Sacramento Area, CA, US",
		role: "Lead Developer; Co-founder",
		description: [
			"Build and utilize in-house and third-party RESTful API’s",
			"Customize, secure, and harden ecommerce solutions (e.g., custom Groupon Merchant API)",
			"Bring software engineering solutions to marketing problems; increase uptime to 99.999%",
			"Architect robust AWS solutions for high-trafficked ecommerce self-hosted websites",
			"Manage multiple outsource teams providing support for multiple legacy codebases",
			"Support and build software tools for Roche via Genentech Research (ReactJS, PHP, HTML, WP, ACF)",
			"Example Projects: Laravel- and Python-based data sanitation and validation; Symfony-based web and desktop for medical credit card billing; modular API Integrations for ESP’s; Magento/WooCommerce/Drupal/Shopify e-commerce; React Native mobile apps ",
		],
	};
}

const myhistory = [
	history_1,
	history_2,
	history_3,
	history_4,
	history_5,
	history_6,
	history_7,
	history_8,
	history_9,
];

export default myhistory;
