import React from "react";

import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { faMailBulk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faTwitter,
	faGithub,
	faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

import Card from "../common/card";
import INFO from "../../data/user";

import "./styles/contact.css";

const Contact = () => {
	return (
		<div className="contacts">
			<Card
				icon={faBriefcase}
				title="Contact"
				body={
					<div className="contacts-body">
						{/*<a*/}
						{/*	className="contact"*/}
						{/*	href={INFO.socials.twitter}*/}
						{/*	target="_blank"*/}
						{/*	rel="noreferrer"*/}
						{/*>*/}
						{/*	<FontAwesomeIcon*/}
						{/*		icon={faTwitter}*/}
						{/*		className="homepage-social-icon"*/}
						{/*	/>*/}
						{/*	<div className="contact-title">Twitter</div>*/}
						{/*	<div className="contact-subtitle">*/}
						{/*		{INFO.socials.twitter}*/}
						{/*	</div>*/}
						{/*</a>*/}
						<a
							className="contact"
							href={INFO.socials.github}
							target="_blank"
							rel="noreferrer"
						>
							<FontAwesomeIcon
								icon={faGithub}
								className="homepage-social-icon"
							/>
							<div className="contact-title">Github</div>
							<div className="contact-subtitle">
								{INFO.socials.github}
							</div>
						</a>

						<a
							className="contact"
							href={INFO.socials.linkedin}
							target="_blank"
							rel="noreferrer"
						>
							<FontAwesomeIcon
								icon={faLinkedin}
								className="homepage-social-icon"
							/>
							<div className="contact-title">Linkedin</div>
							<div className="contact-subtitle">
								{INFO.socials.linkedin}
							</div>
						</a>
						<a
							className="contact"
							href={`mailto:${INFO.main.email}`}
							target="_blank"
							rel="noreferrer"
						>
							<FontAwesomeIcon
								icon={faMailBulk}
								className="homepage-social-icon"
							/>
							<div className="contact-title">Mail</div>
							<div className="contact-subtitle">
								{INFO.main.email}
							</div>
						</a>
					</div>
				}
			/>
		</div>
	);
};

export default Contact;
